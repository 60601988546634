<template>
	<div class="inbox-item h-auto" :class="{ active }">
		<div class="inbox-item-section w-100">
			<router-link
				:to="{
					name: 'app.providers.edit',
					params: { id: provider.id },
				}"
				class="inbox-item-title"
				data-cy="provider-card-title"
			>
				<div class="provider" :style="{ color }">
					<font-awesome-icon fixed-width :icon="['fas', 'circle']" />
				</div>
				{{ fullName }}
			</router-link>
			<h5 class="inbox-item-subtitle">
				{{ provider.phone_number | formatPhoneNumber }}
			</h5>
			<h6 class="inbox-item-subtitle">
				{{ provider.email_address }}
			</h6>
		</div>
	</div>
</template>
<script>
import colors, { defaultColor } from '@/config/colors'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the provider is the current active provider being displayed.
		 *
		 * @return {Boolean}
		 */
		active() {
			return this.provider.id === this.$route.params.id
		},

		/**
		 * Get the provider's schedule color HEX code.
		 *
		 * @return {String}
		 */
		color() {
			const color = colors.find(
				color => color.name === this.provider.color
			)

			return color ? color.code : defaultColor.code
		},

		/**
		 * Get the provider's full name.
		 *
		 * @return {String}
		 */
		fullName() {
			if (!this.provider.user) {
				return this.provider.full_name
			}

			const { first_name, last_name } = this.provider.user

			return `${first_name} ${last_name}`
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ProviderCard',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The provider to be displayed.
		 *
		 * @type {Object}
		 */
		provider: {
			type: Object,
			required: true,
		},
	},
}
</script>
